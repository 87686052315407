import React from 'react';
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

interface GAModalProps extends DialogProps{
  onClose: () => void;
  children: React.ReactNode;
}

export default function GAModal({
  open,
  onClose,
  children,
  ...rest
}: GAModalProps) {
  return (
    <Dialog
    //   fullWidth
    //   sx={{
    //     "& .MuiDialog-paper": {
    //       maxWidth: "none",
    //       width: "1500px",
    //     },
    //   }}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <DialogContent style={{ padding: '28px 24px' }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export function GAModalTitle({ children }: { children: React.ReactNode }) {
  return (
    <div className="text-lg font-semibold text-[#111827]">
      {children}
    </div>
  );
}

export function GAModalDescription({ children }: { children: React.ReactNode }) {
  return (
    <div className="text-sm text-[#374151]">
      {children}
    </div>
  );
}

export function GAModalActions({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex justify-end gap-2 mt-4">
      {children}
    </div>
  );
}
