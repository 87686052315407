import(/* webpackMode: "eager" */ "/tmp/codebuild/output/src578/src/s3/01/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/codebuild/output/src578/src/s3/01/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/tmp/codebuild/output/src578/src/s3/01/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/tmp/codebuild/output/src578/src/s3/01/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/tmp/codebuild/output/src578/src/s3/01/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/tmp/codebuild/output/src578/src/s3/01/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/codebuild/output/src578/src/s3/01/src/app/components/SideMenu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/codebuild/output/src578/src/s3/01/src/app/dashboard/Header.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/codebuild/output/src578/src/s3/01/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/codebuild/output/src578/src/s3/01/src/app/reduxProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/tmp/codebuild/output/src578/src/s3/01/src/context/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/codebuild/output/src578/src/s3/01/src/theme.ts");
