"use client";
import { useState } from "react";
import { ChevronDown, Mail, Phone } from "lucide-react";
import { Popover, Typography, Box, Button } from "@mui/material";
import { usePathname, useRouter } from "next/navigation";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";

import { useAuth } from "@/context/AuthContext";

import "./styles/Header.scss";
import { resetUser } from "@/slices/userSlice";
import { resetOrderRequest } from "@/slices/formSlice";
import { clearRequestData } from "@/slices/requestForm";
import { clearSelectedService } from "@/slices/serviceSlice";
import { resetFiles } from "@/slices/uploadFile";
import GAModal, { GAModalDescription, GAModalTitle } from "@/components/atoms/GAModal";

const ContactItem = ({
  icon,
  label,
  value,
  href
} : {
  icon: React.ReactNode;
  label: string;
  value: string;
  href: string;
}) => (
  <div className="flex items-center gap-4 px-2 py-4 border border-[#D7D7D7] rounded-md">
    <div className="bg-[#086092] rounded-full p-2 text-white">
      {icon}
    </div>
    <div className="flex flex-col gap-1">
      <span className="text-xs">{label}</span>
      <a
        className="text-sm font-medium text-[#111827] underline"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    </div>
  </div>
);

const ContactModal = ({ open, onClose } : { open: boolean; onClose: () => void }) => (
  <GAModal
    open={open}
    onClose={onClose}
    title="Contact Gather"
    fullWidth
    maxWidth="xs"
  >
    <div className="flex flex-col gap-4">
      <GAModalTitle>Need Help?</GAModalTitle>
      <GAModalDescription>
        Do you have questions or need help?
        Please feel free to reach out to us at
        the email below and our team will
        respond to you promptly.  
      </GAModalDescription>
      <ContactItem
        icon={<Phone size={16} />}
        label="Call us"
        value="(561) 337-4472"
        href="tel:5613374472"
      />
      <ContactItem
        icon={<Mail size={16} />}
        label="Send us an email"
        value="contact@gatherrecords.com"
        href="mailto:contact@gatherrecords.com"
      />
    </div>
  </GAModal>
);

const Header: React.FC = () => {
  const pathname = usePathname();
  const userType = useAppSelector((state) => state.user.user_type);

  const [showContactModal, setShowContactModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const router = useRouter();
  const { logout } = useAuth();

  const user = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logout();
    handleClose();
    dispatch(resetUser());
    dispatch(clearRequestData());
    dispatch(resetOrderRequest());
    dispatch(resetFiles());
    dispatch(clearSelectedService());
    router.push("/signin");
  };

  const routeToDashboard = () => {
    router.push("/dashboard");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (pathname.startsWith("/surrogate-intake")) {
    return <></>;
  }

  return (
    <>
      {userType !== "admin_master" && (
        <header className={"app-header"}>
          <img
            onClick={routeToDashboard}
            src={"/icons/dashboard-logo.svg"}
            alt="logo"
          />
          <div className="flex items-center gap-4">
            <Button
              variant="outlined"
              color="dark"
              onClick={() => setShowContactModal(true)}
            >
              Contact Gather
            </Button>
            {user.id !== 0 && (
              <div
                className="user-profle px-3"
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  setAnchorEl(e.currentTarget)
                }
              >
                <div className="data">
                  <img src="/icons/user-avatar.png" alt="User" className="w-8 h-8 rounded-full" />
                  <span>{user.first_name + " " + user.last_name}</span>
                </div>

                <ChevronDown size={16} className="text-[#086092]" />
              </div>
            )}
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                border: "1px solid lightgray",
              }}
            >
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/my-profile");
                  handleClose();
                }}
              >
                Profile
              </Typography>
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/billing");
                  handleClose();
                }}
              >
                Billing
              </Typography>
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/team");
                  handleClose();
                }}
              >
                Agency Team
              </Typography>
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/agency-details");
                  handleClose();
                }}
              >
                Agency Details
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  px: 2,
                  width: "190px",
                  borderTop: "1px solid lightgrey",
                }}
                onClick={handleLogOut}
              >
                Logout
              </Typography>
            </Box>
          </Popover>
          <ContactModal open={showContactModal} onClose={() => setShowContactModal(false)} />
        </header>
      )}
    </>
  );
};

export default Header;
